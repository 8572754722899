import { getEntityClass } from '@geomagic/geonam';
import { CLASSNAME_ASSIGNMENT, CLASSNAME_FUNCLOC } from '@graphql/consts';

const getAssignmentAttributeTypes = (assignmentTypeId, entityClasses) => {
  const entityClass = getEntityClass(entityClasses, CLASSNAME_ASSIGNMENT);
  const { attributeTypes: classAttributesTypes, entityTypes: assignmentTypes } = entityClass;

  if (assignmentTypeId) {
    const { attributeTypes } = assignmentTypes.find(({ id }) => id === assignmentTypeId);

    return [...classAttributesTypes, ...attributeTypes];
  }

  return [...classAttributesTypes];
};

const getFunclocType = (funclocTypeId, { entityTypes }) => {
  return entityTypes.find(({ id }) => id === funclocTypeId) || {};
};

const getFunclocAttributeTypes = (funclocTypeId, entityClasses) => {
  const entityClass = getEntityClass(entityClasses, CLASSNAME_FUNCLOC);
  const funclocType = getFunclocType(funclocTypeId, entityClass);
  return [...(entityClass?.attributeTypes || []), ...(funclocType?.attributeTypes || [])];
};

/**
 * Get the attribute type for the given ids.
 *
 * @param {object} ids
 * @param {Array.object} entityClasses
 * @returns
 */
const getAttributType = (
  { assignmentAttribTypeId, assignmentTypeId, funclocAttribTypeId, funclocTypeId },
  entityClasses
) => {
  if (funclocTypeId && funclocAttribTypeId) {
    const funclocAttributeTypes = getFunclocAttributeTypes(funclocTypeId, entityClasses);
    return funclocAttributeTypes.find(attr => attr.id === funclocAttribTypeId);
  }

  if (assignmentAttribTypeId) {
    const assignmentAttributeTypes = getAssignmentAttributeTypes(assignmentTypeId, entityClasses);
    return assignmentAttributeTypes.find(attr => attr.id === assignmentAttribTypeId);
  }

  return {};
};

export default getAttributType;
