import { getEntityClass } from '@geomagic/geonam';

import { CLASSNAME_FUNCLOC } from '@graphql/consts';

/**
 * Returns the type name of the given funclocTypeId.
 *
 * @param {Array} entityClasses
 * @param {Number} funclocTypeId
 * @returns {String}
 */
const getFunclocTypeName = (entityClasses, funclocTypeId) => {
  const entityClass = getEntityClass(entityClasses, CLASSNAME_FUNCLOC);
  const entityTypes = entityClass?.entityTypes;

  return entityTypes.find(e => e.id === funclocTypeId)?.name;
};

export default getFunclocTypeName;
