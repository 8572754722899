import cloneDeep from 'lodash/cloneDeep';

const isSameReplacePatch = (patchA, patchB) => {
  return patchA.op === 'replace' && patchA.path === patchB.path && patchA.op === patchB.op;
};

/**
 * Merge a new patch into the patches array.
 *
 * @param {Array.object} patches
 * @param {Array.object, object} jsonPatch
 * @returns
 */
const getPatch = (patches, jsonPatch) => {
  const clonedPatches = patches ? cloneDeep(patches) : [];
  const patch = Array.isArray(jsonPatch) ? jsonPatch : [jsonPatch];

  const filteredPatches = clonedPatches.filter(
    clonedPatch => !patch.find(item => isSameReplacePatch(clonedPatch, item))
  );

  return [...filteredPatches, ...patch];
};

export default getPatch;
