/**
 * Add a photo to a given document.
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import getCompressedHashedFile from '@image/getCompressedHashedFile';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';
import getPatch from '@database/getPatch';
import { CLASSNAME_DOCUMENT } from '@graphql/consts';
import getNewFileName from '@utils/getNewFileName';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';

const IMAGE_TYPE_START = 'image/';
const checkDefaultCompression = (type) => type?.startsWith(IMAGE_TYPE_START);

const useStyles = makeStyles(({ spacing }) => ({
  fileInput: {
    display: 'none',
  },
  trigger: {
    height: MOBILE_TRIGGER_SIZE,
    minWidth: MOBILE_TRIGGER_SIZE + spacing(2),
    width: MOBILE_TRIGGER_SIZE + spacing(2),
  },
}));

const AddAPhotoAction = (props) => {
  const { doc, documentTypeId } = props;

  const classes = useStyles(props);
  const fileInputRef = useRef();
  const enqueueLoadingSnackbar = useLoadingSnackbar();

  /**
   *  EVENT HANDLER
   */

  const handleAddPhoto = () => fileInputRef.current.click();

  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    const errors = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const compression = checkDefaultCompression(file.type);

      try {
        if (compression) {
          const execute = async () => {
            const { hash, file: compressedFile } = await getCompressedHashedFile(file);

            const entity = doc.getPatchedEntity();
            const { documents = [] } = entity;
            const fileName = getNewFileName(file, documents);

            const newDocuments = [
              ...documents,
              {
                className: CLASSNAME_DOCUMENT,
                displayName: fileName,
                properties: { contentType: file.type, hash },
                entityType: {
                  documentTypeId,
                },
                hash,
              },
            ];

            await doc.putAttachment({ id: hash, data: compressedFile, type: file.type });
            const newPatch = {
              op: 'replace',
              path: '/documents',
              value: newDocuments,
            };
            const jsonPatch = doc?.jsonPatch;

            await doc.atomicUpdate((oldData) => {
              oldData.jsonPatch = getPatch(jsonPatch, newPatch);
              return oldData;
            });
          };

          await enqueueLoadingSnackbar({
            loadingText: i18n.t('notification.uploadDocument'),
            finishedText: i18n.t('notification.uploadedDocument'),
            finishedVariant: 'success',
            func: execute,
          });
        }
      } catch (error) {
        errors.push(error);
      }
    }
  };

  return (
    <>
      <Trigger
        {...DEFAULT_TRIGGER_ICON_PROPS}
        className={classes.trigger}
        color="primary"
        onClick={handleAddPhoto}
        icon={<AddAPhotoIcon />}
        tooltip={i18n.t('tooltip.addPhoto')}
        variant="contained"
      />
      <input
        accept="image/*"
        capture="environment"
        className={classes.fileInput}
        onChange={handleFileInputChange}
        ref={fileInputRef}
        type="file"
      />
    </>
  );
};

AddAPhotoAction.propTypes = {
  doc: PropTypes.object.isRequired,
  documentTypeId: PropTypes.string.isRequired,
};

export default AddAPhotoAction;
