import flattenDeepByKey from '@utils/flattenDeepByKey';
import { FORM_COMPONENT_CHILDREN_KEY } from '../consts';

/**
 *  Returns all children ids of the given forms.
 *
 * @param {Array} forms Can be pages (forms), blocks, rows
 * @returns
 */
const getChildrenIds = (forms = []) => {
  if (forms.length === 0) return [];

  const childIds = flattenDeepByKey(forms, FORM_COMPONENT_CHILDREN_KEY).map(child => child.id);

  return childIds;
};

export default getChildrenIds;
