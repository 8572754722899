/**
 * A wizard to create/edit a new funcloc.
 */

import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import Badge from '@material-ui/core/Badge';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import MapIcon from '@material-ui/icons/Map';

import { CLASSNAME_FUNCLOC } from '@graphql/consts';
import { i18n } from '@geomagic/i18n';

import FunclocForm from './FunclocForm';
import FunclocMap from './FunclocMap';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  badge: {
    backgroundColor: palette.secondary.main,
  },
  content: {
    background: palette.background.paper,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  paper: {},
  tab: {
    textTransform: 'capitalize',
    [breakpoints.down('sm')]: {
      minWidth: '0px',
    },
  },
  tabs: {
    background: palette.background.default,
    borderBottom: `1px solid ${palette.divider}`,
  },
}));

const FUNCLOC_FORM_ID = 'form';

const getTabs = () => {
  return [
    {
      id: FUNCLOC_FORM_ID,
      name: i18n.t('funcloc.label.tabGeneral'),
      component: FunclocForm,
      icon: <FormatListBulletedIcon />,
    },
    {
      id: 'map',
      name: i18n.t('funcloc.label.tabMap'),
      component: FunclocMap,
      icon: <MapIcon />,
    },
  ];
};

const FunclocWizard = props => {
  const {
    assignmentDoc,
    className,
    entityClass,
    entityClasses,
    getPreviousMap,
    handleClose,
    isMobile,
    mapProps,
    onChange,
    wizardData,
  } = props;

  const assignment = assignmentDoc?.getPatchedEntity() || {};
  const { forms } = assignment;
  const entityTypes = entityClass?.entityTypes;
  const previousMap = useMemo(() => getPreviousMap(), [getPreviousMap]);

  const funclocRef = useRef();
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();

  const tabs = getTabs();
  const TabComponent = tabs[activeStep].component || null;
  const isMobileSM = useMediaQuery(theme => theme.breakpoints.down('sm'));

  /**
   *  GENERAL PROPS
   */

  const triggerProps = {
    size: 'medium',
  };

  /**
   *  EVENT HANDLER
   */

  const handleChangeTab = (event, newValue) => {
    const execute = () => setActiveStep(newValue);

    handleFormValidation(execute);
  };

  const handleFormValidation = execute => {
    const onValidateForm = funclocRef?.current?.onValidateForm;
    const formStepIndex = tabs.findIndex(({ id }) => id === FUNCLOC_FORM_ID);

    if (onValidateForm && activeStep === formStepIndex) {
      onValidateForm().then(() => {
        execute();
      });
    } else {
      execute();
    }
  };

  /**
   *  COMPONENTS
   */

  const getStepComponent = () => {
    return (
      <TabComponent
        assignmentDoc={assignmentDoc}
        entityClass={entityClass}
        entityClasses={entityClasses}
        entityClassName={CLASSNAME_FUNCLOC}
        entityTypes={entityTypes}
        forms={forms}
        funclocRef={funclocRef}
        isMobile={isMobile}
        mapProps={mapProps}
        onChange={onChange}
        onClose={handleClose}
        previousMap={previousMap}
        step={tabs[activeStep]}
        triggerProps={triggerProps}
        wizardData={wizardData}
      />
    );
  };

  return (
    <div className={classNames(classes.root, className)}>
      <Paper className={classes.paper} square>
        <Tabs
          centered
          className={classes.tabs}
          indicatorColor="primary"
          onChange={handleChangeTab}
          scrollButtons="off"
          textColor="primary"
          value={activeStep}
          variant={isMobileSM ? 'fullWidth' : 'standard'}
        >
          {tabs.map(({ id, name, icon, count }) => (
            <Tab
              key={id}
              className={classes.tab}
              label={!isMobileSM && name}
              icon={
                isMobileSM && count ? (
                  <Badge classes={{ badge: classes.badge }} color="secondary" overlap="circular" variant="dot">
                    {icon}
                  </Badge>
                ) : (
                  icon
                )
              }
            />
          ))}
        </Tabs>
      </Paper>
      <div className={classes.content}>{entityTypes && getStepComponent()}</div>
    </div>
  );
};

FunclocWizard.propTypes = {
  assignmentDoc: PropTypes.object.isRequired,
  className: PropTypes.string,
  entityClass: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  getPreviousMap: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  wizardData: PropTypes.object.isRequired,
};

export default FunclocWizard;
