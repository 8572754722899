import MutationConnectDispatchFormElementBlock from '@graphql/mutations/MutationConnectDispatchFormElementBlock';
import QueryDispatch from '@graphql/queries/QueryDispatch';

import { ENTITY_SELECTOR_KEY } from '@database/consts';
import getDefaultSync from '@database/getDefaultSync';
import defaultSelector from '@database/selector';
import getDiffs from '@database/getDiffs';

const DISPATCH_KEY = 'relevantDispatches';
const UPDATE_KEY = 'id';

/**
 * Updates relevant dispatches.
 *
 * @param {object} updateProps
 * @param {object} updateProps.client
 * @param {object} updateProps.database
 * @param {object} updateProps.entity
 * @param {Array.<Object>} updateProps.entityClasses
 * @param {object} updateProps.mapProps
 * @param {object} updateProps.patchedEntity
 */
const updateRelevantDispatches = async updateProps => {
  const { client, database, entity, entityClasses, idMap, mapProps, patchedEntity } = updateProps;
  const relevantDispatches = entity[DISPATCH_KEY];
  const patchedRelevantDispatches = patchedEntity[DISPATCH_KEY];

  if (patchedRelevantDispatches?.length > 0) {
    const { added } = getDiffs(relevantDispatches, patchedRelevantDispatches, UPDATE_KEY);

    if (added.length > 0) {
      for (let i = 0; i < added.length; i++) {
        try {
          const relevantDispatch = added[i];
          const selector = { [ENTITY_SELECTOR_KEY]: relevantDispatch.id, ...defaultSelector };
          const dispatchDoc = await database.dispatches.findOne({ selector }).exec();

          if (dispatchDoc) {
            const createdEntityReference = await getDefaultSync({
              client,
              doc: dispatchDoc,
              entityClasses,
              mapProps,
              refetchQuery: QueryDispatch,
            });

            if (createdEntityReference && relevantDispatch?.blockId) {
              const { blockId } = relevantDispatch;
              const formElementBlockId = idMap.has(blockId) ? idMap.get(blockId) : blockId;
              await client.mutate({
                mutation: MutationConnectDispatchFormElementBlock,
                variables: {
                  dispatchReference: createdEntityReference,
                  formElementBlockId,
                },
              });
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  }
};

export default updateRelevantDispatches;
