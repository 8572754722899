import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import AddOutlineIcon from '@material-ui/icons/AddCircleOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { FORM_PATH, FORM_TYPENAME } from '@consts';
import Progress from '@components/FormComponent/Progress';
import EditFunclocTriggers from '@components/Funcloc/EditFunclocTriggers';
import { i18n } from '@geomagic/i18n';
import useToggleActionDrawer from '@utils/useToggleActionDrawer';

import getFunclocTypeName from './utils/getFunclocTypeName';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    listStyle: 'none',
  },
  icon: {
    marginRight: spacing(2),
    minHeight: 40,
    width: 16,
  },
  title: {
    fontWeight: 500,
  },
}));

const PageSelect = props => {
  const {
    entityClasses,
    forms,
    isMarkdown,
    onChange,
    onEditNewFuncloc,
    onRemoveFuncloc,
    path,
    secondaryAction,
    templateForms,
    title,
    value,
  } = props;
  const activeForm = forms?.[value];
  const hasPages = forms.length + templateForms.length > 0;
  const newFormIndex = forms.length + templateForms.length;

  const typographyProps = {
    noWrap: true,
  };

  const classes = useStyles();
  const toggleActionDrawer = useToggleActionDrawer();

  /**
   *  EVENT HANDLER
   */
  const pages = forms.map((form, index) => ({
    id: form.id,
    label: (
      <div className={classes.root}>
        <Progress forms={[form]} variant="icon" />
        <Typography {...typographyProps}>{form.name}</Typography>
      </div>
    ),
    onClick: event => onChange(event, index),
  }));

  const templatePages = templateForms.map((form, index) => ({
    id: form.id,
    label: (
      <div className={classes.root}>
        <AddOutlineIcon className={classes.icon} />
        <Typography {...typographyProps}>
          {i18n.t(`funcloc.button.add`, {
            variables: {
              name: getFunclocTypeName(entityClasses, form.funclocTypeId),
            },
          })}
        </Typography>
      </div>
    ),
    onClick: event =>
      onEditNewFuncloc({
        template: { ...form, typename: FORM_TYPENAME },
        parentPath: `${FORM_PATH}`,
        path: `${FORM_PATH}/${newFormIndex}`,
      }),
  }));

  const handleClick = () => {
    toggleActionDrawer({
      title,
      items: [...pages, ...templatePages],
    });
  };

  const handleRemovePage = () => {
    onRemoveFuncloc({ path, removeId: activeForm.id });
    onChange(null, 0);
  };

  const handleEditNewFuncloc = () => {
    onEditNewFuncloc({ parentPath: `${FORM_PATH}`, path });
  };

  return (
    <ListItem
      className={classes.root}
      disableGutters
      {...(hasPages && {
        button: true,
        onClick: handleClick,
      })}
    >
      <Progress className={classes.progress} forms={[activeForm]} variant="icon" />
      <ListItemText
        primary={activeForm.name}
        primaryTypographyProps={{ ...typographyProps, className: classes.title, variant: 'h5' }}
        secondary={!isMarkdown ? activeForm.description : ''}
        secondaryTypographyProps={typographyProps}
      />
      {hasPages && <KeyboardArrowDownIcon />}
      {activeForm.fromTemplate && (
        <EditFunclocTriggers onEditNewFuncloc={handleEditNewFuncloc} onDeleteFuncloc={handleRemovePage} />
      )}
      {secondaryAction && secondaryAction}
    </ListItem>
  );
};

PageSelect.propTypes = {
  entityClasses: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  isMarkdown: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onEditNewFuncloc: PropTypes.func.isRequired,
  onRemoveFuncloc: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  secondaryAction: PropTypes.node,
  templateForms: PropTypes.array.isRequired,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default PageSelect;
