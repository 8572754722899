import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { i18n } from '@geomagic/i18n';
import { Map as DefaultMap, Zoom, getLayerStoreInstance, MINIMUM_CONFIG } from '@geomagic/map';

import { toLonLat } from 'ol/proj';
import View from 'ol/View.js';
import useDebounce from '@utils/useDebounce';
import useResizeObserver from '@utils/useResizeObserver';
import useShowPrompt from '@utils/useShowPrompt';

import LocationTracking from './LocationTracking';
import Scale from './Scale';

import enableLongClickInteraction from './utils/enableLongClickInteraction';
import getTrackingOverlay from './utils/getTrackingOverlay';
import setLayerVisible from './utils/setLayerVisible';
import startNavigation from './utils/startNavigation';
import transformLayers from './utils/transformLayers';

const useStyles = makeStyles(({ shape, spacing }) => ({
  root: {
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  customActionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    pointerEvents: 'none !important',
    '&$top': {
      alignItems: 'flex-start',
    },
    '&$bottom': {
      alignItems: 'flex-end',
      paddingBottom: shape.borderRadius * 2,
    },
  },
  customActions: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  bottom: {},
  bottomRight: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'initial',
    justifyContent: 'initial',
  },
  hidden: {
    display: 'none',
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  locationTracking: {
    width: 32,
    borderRadius: shape.borderRadius,
  },
  zoom: {
    width: 32,
    borderRadius: shape.borderRadius,
  },
}));

const getLayers = (view, layerSelectionStore, vectorTileServerUrl) => {
  const layers = [...transformLayers(view, layerSelectionStore)];

  if (!!vectorTileServerUrl) {
    layers.push({
      id: 'offlineMap',
      _id: 'offlineMap',
      type: 'MapboxGL',
      isBackground: true,
      isVisible: true,
      layerPackage: 'default',
      layerValues: '{"opacity":1}',
      name: i18n.t('offlineMap.label'),
      values: `{"url": "${vectorTileServerUrl}"}`,
    });
  }

  return layers;
};

const Map = props => {
  const { children, mapRef, previousMap, rotateViewByOrientation, vectorTileServerUrl, view } = props;
  const layerSelectionStore = getLayerStoreInstance(MINIMUM_CONFIG.mapId);
  const [containerRef, { height, width }] = useResizeObserver();
  const debouncedHeight = useDebounce(height, 200);
  const debouncedWidth = useDebounce(width, 200);
  const showPrompt = useShowPrompt();
  const classes = useStyles();

  /**
   *  MAP CONFIG
   */

  const layers = useMemo(() => getLayers(view, layerSelectionStore, vectorTileServerUrl), [
    view,
    layerSelectionStore,
    vectorTileServerUrl,
  ]);

  const mapConfig = useMemo(
    () => ({
      ...MINIMUM_CONFIG,
      map: { ...MINIMUM_CONFIG.map },
      view: { ...MINIMUM_CONFIG?.view, projection: view?.epsgCode },
    }),
    [view]
  );

  /**
   *  EVENT HANDLER
   */

  const handleLongClick = useCallback(
    (event, features) => {
      const isFeature = features.length > 0;
      const coordinate = isFeature ? features[0]?.getGeometry()?.getCoordinates() : event.coordinate;
      const [lon, lat] = toLonLat(coordinate);

      showPrompt({
        title: i18n.t('map.dialog.navigation.title'),
        content: isFeature
          ? i18n.t('map.dialog.navigation.content.feature', {
              variables: { feature: features[0]?.get('entity')?.displayName },
            })
          : i18n.t('map.dialog.navigation.content.coordinate', {
              variables: { coordinate: `${lat.toFixed(6)}, ${lon.toFixed(6)}` },
            }),
        onOk: () => startNavigation({ lon, lat }),
      });
    },
    [showPrompt]
  );

  /**
   *  EFFECTS
   */

  useEffect(() => {
    const map = mapRef.current?.map;

    if (map) {
      const trackingOverlay = getTrackingOverlay();
      map.addLayer(trackingOverlay);
    }
  }, [mapRef]);

  useEffect(() => {
    const map = mapRef.current?.map;

    if (map) {
      const properties = previousMap ? previousMap.getView().getProperties() : map.getView().getProperties();
      map.setView(
        new View({
          ...properties,
          enableRotation: rotateViewByOrientation,
        })
      );
    }
  }, [mapRef, previousMap, rotateViewByOrientation]);

  useEffect(() => {
    const map = mapRef.current.map;
    map && map.updateSize();
  }, [mapRef, debouncedHeight, debouncedWidth]);

  useEffect(() => {
    const map = mapRef.current.map;
    let interaction;

    if (map) {
      interaction = enableLongClickInteraction(map, handleLongClick);
    }

    return () => {
      if (map && interaction) {
        map.removeInteraction(interaction);
      }
    };
  }, [mapRef, handleLongClick]);

  /**
   * Initial layer feature visibility
   *
   * This can be removed when the map package set visibility correctly.
   */
  useEffect(() => {
    const map = mapRef.current.map;
    if (map) {
      layerSelectionStore.getLayerSelection().selectedLayers.forEach(({ layerId, isVisible }) => {
        setLayerVisible(map, layerId, isVisible);
      });
    }
  }, [mapRef, layerSelectionStore]);

  /**
   *  CLASS OVERRIDES
   */

  const mapClassesOverride = {
    top: classes.hidden,
    bottom: classes.hidden,
    'top-left': classes.hidden,
    'top-right': classes.hidden,
    'bottom-left': classes.hidden,
    'bottom-right': classes.bottomRight,
  };

  return (
    <div className={classes.root} ref={containerRef}>
      {layers && (
        <DefaultMap ref={mapRef} classes={mapClassesOverride} config={mapConfig} layers={layers}>
          {map => {
            return (
              <>
                <div className={classNames(classes.customActionRow, classes.top)}>
                  <Zoom className={classes.zoom} map={map} config={MINIMUM_CONFIG} />
                  <LocationTracking
                    className={classes.locationTracking}
                    isFollowTracking
                    rotateViewByOrientation={rotateViewByOrientation}
                    map={map}
                  />
                </div>
                <div className={classNames(classes.customActionRow, classes.bottom)}>
                  <Scale map={map} />
                  <div className={classes.customActions}>{children}</div>
                </div>
              </>
            );
          }}
        </DefaultMap>
      )}
    </div>
  );
};

Map.propTypes = {
  children: PropTypes.node,
  mapRef: PropTypes.object.isRequired,
  previousMap: PropTypes.object,
  rotateViewByOrientation: PropTypes.bool.isRequired,
  vectorTileServerUrl: PropTypes.string,
  view: PropTypes.object.isRequired,
};

export default Map;
