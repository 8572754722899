/**
 * A map component to draw/edit geometries of a new funcloc.
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import GeoJSON from 'ol/format/GeoJSON';
import pointer from 'json-pointer';

import CheckIcon from '@material-ui/icons/Check';
import DefaultAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { getEntityClass, isAllowedGEOM } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import { useStickySessionState } from '@geomagic/nam-react-core/utils';

import { PRIMARY_TRIGGER_PROPS } from '@consts';
import Map, { POSITION_SETTINGS_KEY } from '@components/Map';
import TriggerDrawInteractions from '@components/Map/TriggerDrawInteractions';
import TriggerModifyInteractions from '@components/Map/TriggerModifyInteractions';
import getGeometryTypesByStyleId from '@components/Map/utils/getGeometryTypesByStyleId';
import useFeatures from '@components/Map/utils/useFeatures';
import useDrawInteraction from '@components/Map/utils/useDrawInteraction';
import useModifyInteraction from '@components/Map/utils/useModifyInteraction';
import useShowPrompt from '@utils/useShowPrompt';

import getFeatures from './getFeatures';
import getDefaultFeatureStyle from '../Dispatch/getFeatureStyle';

const useStyles = makeStyles(({ palette, spacing }) => ({
  bottomToolbar: {
    background: palette.background.default,
    borderTop: `1px solid ${palette.divider}`,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: spacing(),
    paddingTop: spacing(),
  },
}));

const FunclocMap = props => {
  const {
    assignmentDoc,
    CloseComponent,
    entityClass,
    entityClasses,
    entityClassName,
    isReadOnly,
    mapProps,
    onChange,
    previousMap,
    triggerProps,
    wizardData,
  } = props;

  const assignment = assignmentDoc?.getPatchedEntity() || {};
  const { path } = wizardData;
  const hasFormElement = pointer.has(assignment, path);
  const formElement = hasFormElement ? pointer.get(assignment, path) : null;
  const funcloc = hasFormElement ? pointer.get(assignment, path)?.newFuncloc : null;
  const { entityType, featureCollections = [] } = funcloc;
  const { primaryColor, maxExtentZoomLevel, selectColor, srid } = mapProps;

  const features = getFeatures([funcloc], entityClasses);
  const entityClassFuncloc = getEntityClass(entityClasses, entityClassName);

  const { stylesByShapeType } = getGeometryTypesByStyleId(entityType, entityClassFuncloc);

  const isInteractionAllowed = isAllowedGEOM(entityClass) && stylesByShapeType?.length > 0;

  const [positionSettings] = useStickySessionState(POSITION_SETTINGS_KEY);
  const { isTracking = false } = positionSettings || {};
  const mapRef = useRef();
  const showPrompt = useShowPrompt();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditable, setEditable] = useState(false);
  const [selectedGeometryStyle, setSelectedGeometryStyle] = useState();
  const classes = useStyles();
  const theme = useTheme();

  /**
   *  EVENT HANDLER
   */

  const getFeatureStyle = useCallback(getDefaultFeatureStyle(primaryColor, theme), [primaryColor, theme]);

  const handleChangeGeometryStyle = geometryStyle => {
    clearSelection();
    setSelectedGeometryStyle(prev => (!prev ? geometryStyle : null));
  };

  const handleClickSpeedDial = () => {
    setEditable(prev => !prev);
    setSelectedGeometryStyle(false);
  };

  const getNewPatch = newFeatures => {
    const newFuncloc = {
      ...funcloc,
      featureCollections: [
        {
          features: newFeatures,
        },
      ],
    };

    return {
      addId: formElement.id,
      value: {
        newFuncloc,
      },
    };
  };

  const handleDelete = (...args) => {
    showPrompt({
      title: i18n.t('dialog.removeGeometry.title'),
      content: i18n.t('dialog.removeGeometry.content'),
      onOk: async () => {
        const currentFeatures = featureCollections[0]?.features;
        const selectedFeatureId = selectedFeature.getId();
        const newFeatures = currentFeatures.filter(feature => feature.id !== selectedFeatureId);

        const newPatch = getNewPatch(newFeatures);

        onChange(newPatch).then(() => {
          clearSelection();
          enqueueSnackbar(i18n.t('notification.removedGeometry'), {
            key: 'removedGeometry',
            preventDuplicate: true,
            variant: 'success',
          });
        });
      },
    });
  };

  const handleDrawed = async (event, drawedFeature) => {
    if (selectedGeometryStyle) {
      const currentFeatures = featureCollections.length ? featureCollections[0]?.features : [];
      const newGeoJSONFeature = JSON.parse(new GeoJSON().writeFeature(drawedFeature));
      const { properties, ...rest } = newGeoJSONFeature;

      const newFeatures = [
        ...currentFeatures,
        {
          ...rest,
          geometryStyleId: selectedGeometryStyle?.id,
          srid,
        },
      ];

      const newPatch = getNewPatch(newFeatures);

      onChange(newPatch).then(() => {
        setSelectedGeometryStyle(false);
        enqueueSnackbar(i18n.t('notification.addedGeometry'), {
          key: 'addedGeometry',
          preventDuplicate: true,
          variant: 'success',
        });
      });
    }
  };

  const handleModify = async () => {
    const currentFeatures = featureCollections[0]?.features;
    const modifiedFeatureId = modifiedFeature.getId();
    const newGeoJSONFeature = JSON.parse(new GeoJSON().writeFeature(modifiedFeature));
    const { properties, ...rest } = newGeoJSONFeature;
    const modifiedFeatures = currentFeatures.map(feature =>
      feature.id === modifiedFeatureId ? { ...feature, ...rest } : feature
    );

    const newPatch = getNewPatch(modifiedFeatures);

    onChange(newPatch).then(() => {
      clearSelection();
      enqueueSnackbar(i18n.t('notification.updatedGeometry'), {
        key: 'updatedGeometry',
        preventDuplicate: true,
        variant: 'success',
      });
    });
  };

  /**
   *  MAP
   */

  const drawerHandler = { onDrawed: handleDrawed };

  const { animateFeatures, clearSelection, selectedFeature } = useFeatures({
    mapRef,
    features,
    isSelectable: !selectedGeometryStyle,
    maxExtentZoomLevel,
    selectColor,
    style: getFeatureStyle,
    withZoom: false,
  });

  useDrawInteraction({ mapRef, geometryStyle: selectedGeometryStyle }, drawerHandler);

  const { modifiedFeature } = useModifyInteraction({
    mapRef,
    feature: selectedFeature,
    isEditable,
  });

  /**
   *  EFFECTS
   */

  useEffect(() => {
    if (!isTracking) {
      animateFeatures();
    }
  }, [animateFeatures, isTracking]);

  return (
    <>
      <ContentRoot scrollable={false} withPadding={false}>
        <Map {...mapProps} mapRef={mapRef} previousMap={previousMap}>
          {!isReadOnly && isInteractionAllowed && selectedFeature && (
            <TriggerModifyInteractions
              modifiedFeature={modifiedFeature}
              onDelete={handleDelete}
              onModify={handleModify}
              handleToggleOpen={handleClickSpeedDial}
              open={isEditable}
              selectedFeature={selectedFeature}
              triggerProps={{ ...PRIMARY_TRIGGER_PROPS, ...triggerProps }}
            />
          )}
        </Map>
      </ContentRoot>
      {!isReadOnly && (
        <DefaultAppBar position="static" color="inherit">
          <Toolbar className={classes.bottomToolbar}>
            {isInteractionAllowed && (
              <TriggerDrawInteractions
                icon={selectedGeometryStyle && <CheckIcon />}
                onClick={handleChangeGeometryStyle}
                stylesByShapeType={stylesByShapeType}
                triggerProps={{ ...PRIMARY_TRIGGER_PROPS, ...triggerProps }}
              />
            )}
            {CloseComponent}
          </Toolbar>
        </DefaultAppBar>
      )}
    </>
  );
};

FunclocMap.propTypes = {
  assignmentDoc: PropTypes.object.isRequired,
  CloseComponent: PropTypes.node,
  entityClass: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  entityClassName: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  previousMap: PropTypes.object.isRequired,
  triggerProps: PropTypes.object,
  wizardData: PropTypes.object.isRequired,
};

export default FunclocMap;
