import getFeatureCollection from '@components/Map/utils/getFeatureCollection';
/**
 * Return the features of funclocs.
 *
 * @param {Array.<Object>} funclocs
 * @param {Array.<Object>} entityClasses
 * @returns {Array.<Object>}
 */
const getFeatures = (funclocs, entityClasses) => {
  let features = [];

  funclocs.forEach(funcloc => {
    const featureCollection = getFeatureCollection(funcloc, entityClasses);

    if (featureCollection) {
      const newFeatures = featureCollection?.features.map(feature => ({
        ...feature,
        properties: { ...feature.properties, funcloc },
      }));

      features = [...features, ...newFeatures];
    }
  });

  return features;
};

export default getFeatures;
