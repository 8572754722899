import { getFormattedAttributeValue } from '@geomagic/nam-react-core/utils';

import flattenDeepByKey from '@utils/flattenDeepByKey';
import { FORM_COMPONENT_CHILDREN_KEY } from '../consts';

const findParentFuncloc = (id, funclocTypeId, elements) => {
  const parent = elements.find(({ children }) => children?.some(({ id: childrenId }) => childrenId === id));
  if (!parent) return null;
  const { id: parentId, newFuncloc } = parent;
  if (newFuncloc && newFuncloc.entityType?.id === funclocTypeId) {
    return newFuncloc;
  }

  return findParentFuncloc(parentId, funclocTypeId, elements);
};

/**
 * Find the value of a new created funcloc.
 *
 * @param {Number, String} id
 * @param {Number} funclocTypeId
 * @param {Number} funclocAttribTypeId
 * @param {Array} forms
 * @param {Object} pseudoAttributeType
 * @returns {String}
 */
const findValueFromParent = (id, funclocTypeId, funclocAttribTypeId, forms, pseudoAttributeType) => {
  const elements = flattenDeepByKey(forms, FORM_COMPONENT_CHILDREN_KEY);
  const newFuncloc = findParentFuncloc(id, funclocTypeId, elements);

  if (newFuncloc) {
    const attributeValue = newFuncloc.attributeValues.find(
      ({ attributeType }) => attributeType.id === funclocAttribTypeId
    );
    if (attributeValue) {
      return getFormattedAttributeValue(pseudoAttributeType, attributeValue.value);
    }
  }
  return '';
};

export default findValueFromParent;
