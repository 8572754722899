import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(() => ({
  root: {},
}));

const AssignmentListText = props => {
  const { data } = props;
  const entity = data.getPatchedEntity();

  const classes = useStyles();

  return (
    <ListItemText
      className={classes.root}
      primary={entity?.displayName}
      secondary={entity?.shortDescription}
      primaryTypographyProps={{ noWrap: true, variant: 'body1' }}
      secondaryTypographyProps={{ noWrap: true, variant: 'body2' }}
    />
  );
};

AssignmentListText.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AssignmentListText;
