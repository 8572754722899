import uuidv4 from 'uuid/v4';

const copyChildrenWithNewIds = children => {
  return children.map(child => ({
    ...child,
    id: uuidv4(),
    ...(child.children ? { children: copyChildrenWithNewIds(child.children) } : {}),
  }));
};

/**
 * Get a new form/form element from a template with a new funcloc.
 *
 * @param {object} template
 * @param {String} name
 * @param {object} newFuncloc
 * @returns
 */
const getFormFromTemplate = (template, name, newFuncloc) => {
  return {
    ...template,
    children: copyChildrenWithNewIds(template.children),
    fromTemplate: true,
    name,
    newFuncloc,
    id: uuidv4(),
    templateName: template.name,
    templateId: template.id,
    usedAsTemplate: false,
  };
};

export default getFormFromTemplate;
