/**
 * A form to create and update an entity.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import { AutoForm } from '@geomagic/forms';
import {
  compareByReference,
  getAttributeTypesByClassAndType,
  getRawAttributeValue,
  getReference,
  getTypedAttributeValue,
} from '@geomagic/geonam';

import useResizeObserver from '@utils/useResizeObserver';
import { formValidationHandler, getAutoFormPropsByAttributeType, getDataGroupsFromFields } from '../utils';
import DataGroupItem from './DataGroupItem';

const ADDITIONAL_WIDTH = 40;

const createFormSchemaAndUI = (attributeTypes, entity, isReadOnly, hideReadOnlyFields) => {
  const requiredList = [];
  const properties = {};

  const ui = {};

  attributeTypes.forEach((attributeType, index) => {
    if (!attributeType.readOnly || entity) {
      if (hideReadOnlyFields && attributeType.readOnly) {
        return;
      }

      const { fieldSchema, fieldUI } = getAutoFormPropsByAttributeType(attributeType, entity, isReadOnly);

      if (fieldSchema) {
        properties[index] = { ...fieldSchema, identifier: attributeType.id };
      }

      ui[index] = fieldUI;

      if (attributeType.mandatory && !attributeType.readOnly) {
        requiredList.push(index.toString());
      }
    }
  });

  return {
    schema: {
      type: 'object',
      required: requiredList,
      properties,
    },
    ui,
  };
};

const getAttributeValues = (attributeTypes, values) => {
  return attributeTypes.map((attributeType, index) => {
    const typedValue = values[String(index)];

    return {
      attributeType: getReference(attributeType),
      value: getRawAttributeValue(attributeType, typedValue),
    };
  });
};

const EntityForm = props => {
  const {
    children,
    defaultValues: initialValues,
    entity,
    entityClass,
    entityClasses,
    entityClassName,
    entityTypeId,
    expandedGroups = {},
    formId,
    hideReadOnlyFields = false,
    isReadOnly,
    isRequiredFieldsOnly,
    onCancel,
    prefilledAttributeValues = [],
    setExpandedGroups = () => {},
    ...autoFormProps
  } = props;

  const [ref, { width }] = useResizeObserver();

  const entityType = entityClass.entityTypes.find(({ id }) => entityTypeId && id === entityTypeId);

  if (!entityType) {
    throw new Error('noEntityType');
  }

  const attributeTypes = getAttributeTypesByClassAndType(entityClasses, entityClassName, entityType?.id);
  const [schemaUI, setSchemaUI] = useState(() => createFormSchemaAndUI(attributeTypes, entity, hideReadOnlyFields));
  const { schema, ui } = schemaUI;

  const defaultValues = {};

  let entityId;
  if (entity) {
    entityId = entity && entity.id;

    attributeTypes.forEach((aT, index) => {
      const attributeValue = entity?.attributeValues?.find(aV => compareByReference(aT, aV.attributeType));
      const typedValue = getTypedAttributeValue(aT, attributeValue?.value);

      if (typedValue !== null && typedValue !== undefined) {
        defaultValues[index] = typedValue;
      }
    });
  } else {
    initialValues
      ? initialValues.forEach((item, index) => {
          const attributeType = attributeTypes.find(({ id }) => id === item?.attributeType?.id);
          const value = item?.value;

          if (attributeType && value) {
            defaultValues[index] = getTypedAttributeValue(attributeType, value);
          }
        })
      : attributeTypes.forEach((aT, index) => {
          const presetDefault = prefilledAttributeValues.find(PAV => {
            return (PAV.attributeTypeId || PAV.funclocAttribTypeId) === aT.id;
          });
          const defaultValue = (presetDefault && presetDefault.value) || aT.defaultValue;
          const typedValue = getTypedAttributeValue(aT, defaultValue);

          if (typedValue !== null && typedValue !== undefined) {
            defaultValues[index] = typedValue;
          }
        });
  }

  /**
   *  EVENT HANDLER
   */

  const buildNewEntity = values => {
    return getAttributeValues(attributeTypes, values);
  };

  const isCustomChildren = isFunction(children);

  /**
   *  EFFECTS
   */

  useEffect(() => {
    setSchemaUI(createFormSchemaAndUI(attributeTypes, entity, isReadOnly, hideReadOnlyFields));
    // Only change if the entityTypeId is changed. AttributeTypes is changing constantly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityTypeId, entity]);

  return (
    <div ref={ref}>
      <AutoForm
        id={formId ? formId : 'entityForm_' + entityId}
        schema={schema}
        ui={ui}
        defaultValues={defaultValues}
        onError={formValidationHandler}
        {...autoFormProps}
        onSubmit={(values, formContext) => {
          autoFormProps.onSubmit && autoFormProps.onSubmit(buildNewEntity(values), formContext);
        }}
        onChange={(values, formContext) => {
          autoFormProps.onChange && autoFormProps.onChange(buildNewEntity(values), formContext);
        }}
      >
        {(fields, formContext) => {
          const attributeFieldsSorted = [];

          attributeTypes.forEach(attributeType => {
            const fieldComponent = fields.find(field => field?.props?.definition?.identifier === attributeType.id);

            if (fieldComponent) {
              if (isRequiredFieldsOnly) {
                if (fieldComponent.props?.definition?._isRequired) {
                  attributeFieldsSorted.push(fieldComponent);
                }
              } else {
                attributeFieldsSorted.push(fieldComponent);
              }
            }
          });

          const dataGroups =
            expandedGroups && setExpandedGroups
              ? getDataGroupsFromFields(attributeFieldsSorted)
              : attributeFieldsSorted;

          const extendedFields = dataGroups.map(dataGroup => (
            <DataGroupItem
              dataGroup={dataGroup}
              depth={0}
              elements={fields}
              expandedGroups={expandedGroups}
              getId={element => element.key}
              key={dataGroup.key}
              path={[dataGroup.name]}
              setExpandedGroups={setExpandedGroups}
              width={width + ADDITIONAL_WIDTH}
            />
          ));

          return isCustomChildren
            ? children(
                extendedFields,
                {
                  ...formContext,
                  submit: async () => {
                    const values = await formContext.submit();
                    return buildNewEntity(values);
                  },
                  attributeTypes,
                  previousValues: buildNewEntity(defaultValues),
                },
                fields
              )
            : extendedFields;
        }}
      </AutoForm>
    </div>
  );
};

EntityForm.propTypes = {
  children: PropTypes.func,
  defaultValues: PropTypes.array,
  entity: PropTypes.object,
  entityClass: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  entityClassName: PropTypes.string.isRequired,
  entityTypeId: PropTypes.number.isRequired,
  expandedGroups: PropTypes.object,
  formId: PropTypes.string,
  hideReadOnlyFields: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequiredFieldsOnly: PropTypes.bool,
  onCancel: PropTypes.func,
  prefilledAttributeValues: PropTypes.array,
  relations: PropTypes.array,
  setExpandedGroups: PropTypes.func,
};

export default EntityForm;
