import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';

import { Trigger } from '@geomagic/geonam';

import { PRIMARY_TRIGGER_PROPS } from '@consts';

const useStyles = makeStyles(({ palette, shape, spacing }) => ({
  root: {},
  isError: {
    border: `2px solid ${palette.error.main}`,
  },
  selectBox: {
    border: `2px solid ${palette.primary.main}`,
    borderRadius: shape.borderRadius,
    padding: spacing(),
  },
  trigger: {
    height: 78,
    padding: spacing(1.5),
    '& .MuiButton-label': {
      display: '-webkit-box',
      overflow: 'hidden',
      overflowWrap: 'break-word',
      textOverflow: 'clip',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      WebkitHyphens: 'auto',
      MozHyphens: 'auto',
      hyphens: 'auto',
      whiteSpace: 'normal',
    },
  },
}));

const EnumSelect = props => {
  const { error, getOptionLabel, label, onChange, options = [], required, value } = props;
  const classes = useStyles(props);

  return (
    <>
      <FormControl focused fullWidth margin="normal" required={required} size="medium" variant="outlined">
        <FormLabel>{label}</FormLabel>
        <div className={classNames(classes.selectBox, { [classes.isError]: !!error })}>
          <Grid alignItems="center" container spacing={2}>
            {options.map(option => {
              const optionLabel = getOptionLabel(option);
              const isSelected = value === option.value;
              return (
                <Grid key={'option' + optionLabel} item xs={6} sm={3} md={3} lg={2}>
                  <Trigger
                    {...PRIMARY_TRIGGER_PROPS}
                    className={classes.trigger}
                    color={isSelected ? 'primary' : 'default'}
                    fullWidth
                    onClick={event => onChange(event, isSelected ? undefined : option.value)}
                  >
                    {optionLabel}
                  </Trigger>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </FormControl>
    </>
  );
};

EnumSelect.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  getOptionLabel: PropTypes.func.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  required: PropTypes.bool,
  value: PropTypes.object,
};

export default EnumSelect;
