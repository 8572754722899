import pick from 'lodash/pick';
import pointer from 'json-pointer';

import { FORM_TYPENAME } from '@consts';
import getChildrenIds from '@components/FormComponent/utils/getChildrenIds';
import getDiffs from '@database/getDiffs';
import getFilePropsFromDocumentEntity from '@database/getFilePropsFromDocumentEntity';
import { getReference } from '@geomagic/geonam';
import { MutationDeleteDocuments } from '@geomagic/geonam-graphql';
import MutationDeleteLocationRecord from '@graphql/mutations/MutationDeleteLocationRecord';
import MutationUpdateForm from '@graphql/mutations/MutationUpdateForm';
import MutationUpdateFormElement from '@graphql/mutations/MutationUpdateFormElement';

const UPDATE_KEY = 'id';

const getNewFuncloc = (newFuncloc, mapProps) => {
  const { attributeValues, entityType, featureCollections } = newFuncloc;

  const hasFeatures = featureCollections.length > 0 && featureCollections[0].features.length > 0;

  return {
    accessPermissions: [],
    className: 'Funcloc',
    entityTypeId: entityType.id,
    attributeValues: attributeValues
      .filter(attributeValue => attributeValue.value)
      .map(attributeValue => ({
        attributeTypeId: attributeValue.attributeType.id,
        value: attributeValue.value,
      })),
    geometries: hasFeatures
      ? featureCollections[0].features.map(feature => ({
          geometry: { ...feature.geometry, srid: mapProps.srid },
          geometryStyleId: feature.geometryStyleId,
        }))
      : [],
    relations: [],
  };
};

const updateDocuments = async (update, path, updateProps) => {
  const { client, doc, entity } = updateProps;
  const newFiles = [];
  const oldDocuments = pointer.has(entity, path) ? pointer.get(entity, path).documents : [];
  const { documents: patchedDocuments } = update;
  const { added, removed } = getDiffs(oldDocuments, patchedDocuments, UPDATE_KEY);

  if (removed.length > 0) {
    await client.mutate({
      mutation: MutationDeleteDocuments,
      variables: { documents: removed.map(item => getReference(item)) },
    });
  }

  if (added.length > 0) {
    for (let j = 0; j < added.length; j++) {
      const { hash, name, type } = getFilePropsFromDocumentEntity(added[j]);

      const attachment = await doc.getAttachment(hash);
      const blob = await attachment.getData();
      const file = new File([blob], name, { type });

      newFiles.push(file);
    }

    return { documents: newFiles };
  }
};

const updateLocationRecords = async (update, path, updateProps) => {
  const { client, entity } = updateProps;
  const oldLocationRecords = pointer.has(entity, path) ? pointer.get(entity, path).locationRecords : [];
  const { locationRecords: patchedLocationRecords } = update;
  const { added, removed } = getDiffs(oldLocationRecords, patchedLocationRecords, UPDATE_KEY);

  if (removed.length > 0) {
    for (let j = 0; j < removed.length; j++) {
      const { id: removedId } = removed[j];
      await client.mutate({
        mutation: MutationDeleteLocationRecord,
        variables: { id: removedId },
      });
    }
  }

  if (added.length > 0) {
    return {
      locationRecords: added.map(({ geolocationPositions }) => ({ geolocationPositions })),
    };
  }
};

const updatePatchesWithNewIds = (update, form, patches, idMap) => {
  const temporaryChildrenIds = getChildrenIds([update]);
  const newChildrenIds = getChildrenIds([form]);
  for (let index = 0; index < temporaryChildrenIds.length; index++) {
    const key = temporaryChildrenIds[index];
    const value = newChildrenIds[index];
    idMap.set(key, value);
  }

  return patches.map(patch => {
    const id = patch?.value?.id;
    const templateId = patch?.value?.templateId;
    if (id || templateId) {
      const newId = idMap.get(id);
      const newTemplateId = idMap.get(templateId);
      if (newId || newTemplateId) {
        return {
          ...patch,
          value: {
            ...patch.value,
            ...(newId ? { id: newId } : {}),
            ...(templateId ? { templateId: newTemplateId } : {}),
          },
        };
      }
    }
    return patch;
  });
};

/**
 * Updates the forms and form elements according to the patches.
 *
 * @param {object} updateProps
 * @param {object} updateProps.client
 * @param {object} updateProps.doc
 * @param {object} updateProps.entity
 * @param {object} updateProps.mapProps
 */
const updateFormsAndElements = async updateProps => {
  const { client, doc, idMap, mapProps } = updateProps;
  let patches = doc?.jsonPatch ? [...doc.jsonPatch] : [];
  const patchesLength = patches.length;

  for (let i = 0; i < patchesLength; i++) {
    const { op, path, value: update } = patches[i];
    const { templateId, typename } = update;
    let { id } = update;

    const isFunclocAdd = op === 'add' && update.newFuncloc;
    const isDocumentUpdate = typename === 'FormElementDocuments' || typename === 'FormElementPictures';
    const isEnumUpdate = typename === 'FormElementEnum';
    const isFieldUpdate = typename === 'FormElementField';
    const isLocationRecordUpdate = typename === 'FormElementLocRecording';

    let elementUpdate;
    const isUpdateForm = typename === FORM_TYPENAME;

    if (isDocumentUpdate) {
      elementUpdate = await updateDocuments(update, path, updateProps);
    }

    if (isEnumUpdate) {
      elementUpdate = pick(update, 'statusValue');
    }

    if (isFieldUpdate) {
      elementUpdate = pick(update, 'value');
    }

    if (isLocationRecordUpdate) {
      elementUpdate = await updateLocationRecords(update, path, updateProps);
    }

    if (isFunclocAdd) {
      elementUpdate = { newFuncloc: getNewFuncloc(update.newFuncloc, mapProps) };
      id = templateId;
    }

    if (elementUpdate) {
      if (isUpdateForm) {
        const { data } = await client.mutate({
          mutation: MutationUpdateForm,
          variables: { form: { id, ...elementUpdate }, srid: mapProps.srid },
        });
        const form = data.updateForm;
        if (isFunclocAdd) {
          patches = updatePatchesWithNewIds(update, form, patches, idMap);
        }
      } else {
        const { data } = await client.mutate({
          mutation: MutationUpdateFormElement,
          variables: { formElement: { id, ...elementUpdate } },
        });
        const formElement = data.updateFormElement;
        if (isFunclocAdd) {
          patches = updatePatchesWithNewIds(update, formElement, patches, idMap);
        }
      }
    }
  }
};

export default updateFormsAndElements;
